import { Push } from "@ionic-native/push/ngx";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  NavParams,
  ToastController,
} from "@ionic/angular";
import { AlertServiceService } from "src/app/common-services/alert-service.service";
import { TrackingService } from "src/app/services/tracking.service";
import { Utils } from "../../services/utils.service";
import { NailaService } from "../../services/naila.service";
// import { Utils } from "../Rentals Management/services/utils.service";
// import { TrackingService } from "../services/tracking.service";
// import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { Browser } from "@capacitor/browser";
import { Content } from "ionic-angular";
import {
  InAppBrowser,
  InAppBrowserOptions,
  InAppBrowserEvent,
} from "@ionic-native/in-app-browser/ngx";
import { Camera, CameraResultType } from "@capacitor/camera";
import { error } from "console";
// import { AlertServiceService } from "../common-services/alert-service.service";
// import { NailaservicePage } from "../Rentals Management/pages/nailaservicepage/nailaservicepage";
// import {NailaService} from ""

@Component({
  selector: "app-quiz-modal",
  templateUrl: "./quiz-modal.component.html",
  styleUrls: ["./quiz-modal.component.scss"],
})
export class QuizModalComponent implements OnInit {
  count = 0;

  questions = [];
  questionsvideo = [
    {
      heading:
        "1. Would you like sharing your Age with us so we help you serve better?",
      title: "**Choose anyone from the options below",
      option: ["<20", "20-25", "25-30", "30-40", "40+"],
      ischeckbox: false,
    },
  ];
  ques: any = [];
  callgettagresult: any;
  answer: any;
  selectdate;
  imageData: any;
  constructor(
    private alertService: AlertServiceService,
    private modalController: ModalController,
    private router: Router,
    private toast: ToastController,
    private api: TrackingService,
    private apisc: NailaService,
    private utilservice: Utils,
    private navParams: NavParams,
    private navCtrl: NavController,
    private loadingController: LoadingController,
    private iab: InAppBrowser
  ) {
    this.selectdate = "";
    this.callgettagresult = this.utilservice.callgettagresult;
    // let qu=  [{
    //   heading: "1. What do you do for a living?",
    //   title: "*Choose anyone from the options below",
    //   option: [
    //     "Student",
    //     "Government Job",
    //     "Private Job",
    //     "Business",
    //     "Retired",
    //     "Other",
    //   ],
    //   ischeckbox: false,
    // },
    // {
    //   'heading': "2. How many family members lives along with you? ",
    //   'title': "*Choose anyone from the options below",
    //   'option': ["1-2", "3-4", "5", "More than 5"],
    //   'ischeckbox': false,
    // },
    // {
    //   //**change for dynamic brand */
    //   'heading':`3. How likely are you to recommend ${this.callgettagresult.brand} to your known?`,

    //   'title': "*Choose anyone from the options below",
    //   'option': ["1", 2, 3, 4, 5, 6, 7, 8, 9, 10],
    //   'ischeckbox': true,
    // },];
    // this.questions=qu;

    // this.ques =
    //   this.navParams.data["requestFrom"] == "win"
    //     ? this.questions
    //     : this.questionsvideo;

    this.getQuestions();
    this.createAnswer();
  }

  ngOnInit() {}

  closeModal() {
    const onClosedData: string = "Wrapped Up!";
    this.modalController.dismiss(this.navParams.data);
  }

  createAnswer = () => {
    this.answer = {
      device_id: window.localStorage.getItem("device_id"),
      product_id: this.callgettagresult.id,
      answers: [],
      user_brand_id: window.localStorage.getItem("brand_id"),
    };
  };

  activate;

  delayTransition(
    event,
    i,
    qid,
    answer,
    ansid,
    brandId,
    questionType,
    goToId,
    is_conditional
  ) {
    this.activate = true;
    setTimeout(() => {
      // this.showStatus=false;
      if (questionType == "0") {
        this.dataChange(
          i,
          qid,
          answer,
          ansid,
          brandId,
          questionType,
          goToId,
          is_conditional
        );
      } else if (questionType == "1") {
        this.selectedDate(
          event,
          i,
          qid,
          answer,
          ansid,
          brandId,
          questionType,
          goToId,
          is_conditional
        );
      } else if (questionType == "2") {
        this.selectedName(
          event,
          i,
          qid,
          answer,
          ansid,
          brandId,
          questionType,
          goToId,
          is_conditional
        );
      }
    }, 200);
  }

  dataChange(
    i,
    qid,
    answer,
    ansid,
    brandId,
    questionType,
    goToId,
    is_conditional
  ) {
    this.showHideAutoLoader();
    const answerobj = {
      question_id: qid,
      answer: answer,
      // answer_id: ansid,
      answer_id: "",
      brand_id: brandId,
    };

    if (questionType == "0") {
      if (is_conditional == "1") {
        this.calculateNextQuestion(this.questions, goToId);
        setTimeout(() => {
          this.count = 0;
          // let diffToJump = Number(goToId) - Number(qid)
          this.count = this.count + this.abcd;
        }, 100);
        this.answer.answers.push(answerobj);
      } else {
        setTimeout(() => {
          // this.showStatus=false;
          this.count++;
        }, 200);

        // this.count++;

        this.answer.answers.push(answerobj);
      }
    }
    if (questionType == "2") {
      i--;
    }

    const data = {
      user_id: localStorage.getItem("userid"),
      tag_id: localStorage.getItem("tagId"),
      product_id: window.localStorage.getItem("productId"),
      // product_id: 10,
      device_id: localStorage.getItem("device_id"),
      otype: "DATA_FORM_ONE_SUBMITTED",
      question_id: qid,
    };

    if (i == this.questions.length - 1) {
      /** Charu  */

      this.apisc.trackingApi(data).subscribe(
        (res) => {
          this.hideContent = true;
          if (questionType == "0") {
            this.saveAnswers();
          }
        },
        (err) => {
          this.hideContent = true;
        }
      );

      /** Charu  */
    } else {
      //let quizObj=this.questions[i]

      this.apisc.trackingApi(data).subscribe(
        (res) => {
          this.hideContent = true;
        },
        (err) => {
          this.hideContent = true;
        }
      );
      // this.alctrl.dismiss()
      this.loadingController.dismiss();
    }
    if (questionType == "4") {
      this.answer.answers.push(answerobj);
    }
  }
  saveAnswers = () => {
    this.utilservice.showConfetti();
    this.showHideAutoLoader();
    this.apisc.saveAnswers(this.answer).subscribe(
      (_res: any) => {
        window.localStorage.setItem("save_answer", "true");
        if (_res.status_code == 200) {
          // this.alertService.presentAlert("", "Answer saved successfully.");
          this.presentToast("Answer saved successfully.");
          if (this.navParams?.data["requestFrom"] == "win") {
            this.router.navigate(["/verifyit-rewards"]);
            this.closeModal();
          } else if (this.navParams.data["requestFrom"] == "default") {
            if (
              window.localStorage.getItem("scan_flow") == "2" ||
              window.localStorage.getItem("scan_flow") == "4"
            ) {
              this.closeModal();
              window.localStorage.setItem("hasquizModal", "1");
              // this.utilservice.LoadSurpriseModal();
              if (window.localStorage.getItem("tagId") == "5096") {
                this.utilservice.openVideoModal();
              }
              this.loadingController.dismiss();
            } else {
              this.utilservice.LoadSurpriseModal();
              this.closeModal();
            }
          } else if (this.navParams.data["requestFrom"] == "video") {
            this.closeModal();
          }
        } else {
          this.alertService.presentAlert(
            "",
            "Error Occurred.Please try again later."
          );
        }
      },
      (err) => {
        this.alertService.presentAlert(
          "",
          "Error Occurred.Please try again later."
        );
        this.closeModal();
      }
    );
  };

  selectedDate(
    event,
    i,
    qid,
    answer,
    ansid,
    brandId,
    questionType,
    goToId,
    is_conditional
  ) {
    const answerobj = {
      question_id: qid,
      answer: this.selectdate,
      // answer_id: ansid,
      answer_id: "",
      brand_id: brandId,
    };
    // this.answer.answers.push(answerobj);
    this.answer.answers.push(answerobj);
    if (is_conditional == "1") {
      this.calculateNextQuestion(this.questions, goToId);
      this.count = 0;
      this.count = this.count + this.abcd;
    } else {
      this.count++;
    }
    if (i == this.questions.length - 1) {
      this.saveAnswers();
    }
    // this.count++;
  }
  selectName = "";
  abcd;

  async takePicture() {
    const image = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
    });

    console.log("image", image);
    return image;
  }

  selectedName(
    event,
    i,
    qid,
    answer,
    ansid,
    brandId,
    questionType,
    goToId,
    is_conditional
  ) {
    this.showHideAutoLoader();
    const answerobj = {
      question_id: qid,
      answer: this.selectName,
      // answer_id: ansid,
      answer_id: "",
      brand_id: brandId,
    };
    this.answer.answers.push(answerobj);
    if (is_conditional == "1") {
      this.calculateNextQuestion(this.questions, goToId);

      this.count = 0;
      // let diffToJump = Number(goToId) - Number(qid)
      this.count = this.count + this.abcd;
    } else {
      this.count++;
    }
    // i=i+1
    this.selectName = "";

    if (i == this.questions.length - 1) {
      /** Charu  */

      this.saveAnswers();
      /** Charu  */
    }
    // this.dataChange(i, qid, answer, ansid, brandId, questionType)
  }

  //**charu Start  for get Question */
  getQuestions() {
    // console.log("ques res", this.navParams.data["res"])
    // let data = {
    //   brand_id: window.localStorage.getItem("brand_id"),
    //   product_id: window.localStorage.getItem("productId"),
    // };

    // this.apisc.getQuestion(data).subscribe(
    //   (res: any) => {
    if (this.navParams.data["res"].message == "Success") {
      this.questions = this.navParams.data["res"].data.question;
      console.table("modal this.questions", this.questions);
    } else if (this.navParams.data["requestFrom"] == "default") {
      //new flow change

      // this.utilservice.LoadSurpriseModal();

      //new flow change

      this.closeModal();
    }
    // },

    // (err) => {
    //   alert(JSON.stringify(err));
    // }
    // );
  }
  //**Charu End */

  async presentToast(data) {
    const toast = await this.toast.create({
      message: data,
      duration: 3000,
    });
    toast.present();
  }

  //trev new code

  test() {
    this.frame();
  }

  randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  frame() {
    // confetti.create()({
    //   angle: this.randomInRange(20, 125),
    //   spread: this.randomInRange(100, 100),
    //   particleCount: this.randomInRange(300, 100),
    //   origin: { y:0, }
    // });
    // confetti.create()({
    //   angle: this.randomInRange(20, 125),
    //   spread: this.randomInRange(100, 100),
    //   particleCount: this.randomInRange(300, 100),
    //   origin: { y:1 }
    // });
    // confetti.create()({
    //   resize: true,
    //   particleCount: 500,
    //   angle: 120,
    //   spread: 200,
    //   origin: { x:1 },
    //   gravity: 5
    // });
  }
  hideContent = true;

  delayText(goToId) {
    this.hideContent = false;
    if (goToId == 0) this.saveAnswers();
    // setTimeout(function(){

    // }, 1000);
  }

  showHideAutoLoader() {
    this.loadingController.create({
      message: "",
    });
  }

  async openBrowser(
    event,
    i,
    qid,
    answer,
    ansid,
    brandId,
    questionType,
    goToId,
    is_conditional
  ) {
    this.showHideAutoLoader();
    const answerobj = {
      question_id: qid,
      answer: answer,
      // answer_id: ansid,
      answer_id: "",
      brand_id: brandId,
    };

    this.answer.answers.push(answerobj);
    this.saveAnswers();
    this.closeModal();
    if (goToId == "/review") {
      this.router.navigateByUrl("/customer-review");
      this.ProductReviewClick(qid);
    } else if (goToId == "/brand-page") {
      this.closeModal();
      this.router.navigate(["/verifyit-product-catalog"], {
        queryParams: { product_id: window.localStorage.getItem("productId") },
      });
    } else {
      const browser = this.iab.create(goToId);
    }
  }

  ProductReviewClick(qid) {
    let shareData = {
      user_id: window.localStorage.getItem("userid"),
      tag_id: window.localStorage.getItem("tagId"),
      product_id: this.callgettagresult.product_id,
      device_id: window.localStorage.getItem("device_id"),
      otype: "Product_Review_Click",
      question_id: qid,
    };
    this.apisc.reviewTracking(shareData).subscribe(
      (res: any) => {},
      (err) => {
        alert(JSON.stringify(err));
      }
    );
  }

  openProductPage(
    event,
    i,
    qid,
    answer,
    ansid,
    brandId,
    questionType,
    name,
    goToId,
    is_conditional
  ) {
    this.showHideAutoLoader();
    // debugger
    const answerobj = {
      question_id: qid,
      answer: answer,
      // answer_id: ansid,
      answer_id: ansid,
      brand_id: brandId,
    };
    this.answer.answers.push(answerobj);
    this.saveAnswers();
    this.utilservice.particularProductId = goToId;
    this.utilservice.openParticularProduct();
  }

  openNextPageOnIconAndImage(
    event,
    i,
    qid,
    answer,
    ansid,
    brandId,
    questionType,
    name,
    goToId,
    is_conditional
  ) {
    this.showHideAutoLoader();
    // debugger
    const answerobj = {
      question_id: qid,
      answer: answer,
      // answer_id: ansid,
      answer_id: ansid,
      brand_id: brandId,
    };
    this.answer.answers.push(answerobj);
    if (is_conditional == "1") {
      this.calculateNextQuestion(this.questions, goToId);
      this.count = 0;
      this.count = this.count + this.abcd;
    } else {
      this.count++;
    }
    if (i == this.questions.length - 1) {
      this.saveAnswers();
    }
  }

  calculateNextQuestion(data, qid) {
    console.log("calculate next question");
    let index = data.indexOf(qid);

    data.forEach((element, index) => {
      if (element.id == qid) {
        this.abcd = index;
        return index;
      }
    });
  }

  uploadPicture(i, qid, ansid, brandId) {
    this.showHideAutoLoader();
    this.takePicture().then((data) => {
      console.log("dataChange que type 0 data", data);
     this.compressBase64Image(data.base64String).then(base64=> {
      console.log("dataChange que type 0 base64", base64);
     }, error => {
      console.log("dataChange que type 0 error", error);
     });
      

      // debugger
      const answerobj = {
        question_id: qid,
        answer: data.base64String,
        // answer_id: ansid,
        answer_id: "",
        brand_id: brandId,
      };
      this.answer.answers.push(answerobj);
      this.count++;
      if (i == this.questions.length - 1) {
        this.saveAnswers();
      }
    });
  }

  compressBase64Image(base64String: string, quality: number = 0.3): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const image = new Image();
        image.src = base64String;
  
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
  
          const maxWidth = 100; // Set your desired max width
          const maxHeight = 100; // Set your desired max height
  
          let width = image.width;
          let height = image.height;
  
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
  
          context.drawImage(image, 0, 0, width, height);
  
          const compressedBase64 = canvas.toDataURL('image/jpeg', quality);
          resolve(compressedBase64);
        };
      } catch (error) {
        reject(error);
      }
    });
  }
}
