import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-brand-modal',
  templateUrl: './select-brand-modal.component.html',
  styleUrls: ['./select-brand-modal.component.scss'],
})
export class SelectBrandModalComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  selectBrand(brand) {
    this.modalCtrl.dismiss(brand);
  }

}
