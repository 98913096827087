import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss'],
})
export class TermsConditionComponent implements OnInit {
  @Input() image: any;
  constructor(private _modalCtrl: ModalController) { }

  ngOnInit() {}

  dismiss() {
    this._modalCtrl.dismiss();
  }
}
