import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable,EventEmitter } from '@angular/core';
import { MainAppSetting } from 'src/app/conatants/MainAppSetting';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Utils {
    storage:any;
    cartdata:any
    bookingdata:any;
    NFCsuccessmsg:any;
    menuTitle;
    particularProductId;
    isProductInfo;
    user_upi;
    cashbackAmount;
    source_token;
    user_name;
    winMessage;
    winLink;
    winText;
    newpassword;
    newflow;
    notification_id;
    brand_id;
    winLossAlgoData;
    usernotwon
    callgettagresult:any;
    callrecordscanresult:any
    certificateData:any;
    cartitem:any;
    userrole:any;
    productId;
    royaltyData;
    productCatalogInfo;
    hidenfc;
  

    userType:any;
    flipsurprise_modal :EventEmitter<boolean> = new EventEmitter();;
    LoadPage:EventEmitter<boolean> = new EventEmitter();
    LoadModal: EventEmitter<boolean> = new EventEmitter();
    share_product:EventEmitter<boolean> = new EventEmitter();
    submit_upi:EventEmitter<boolean> = new EventEmitter();
    trigger_location:EventEmitter<boolean> = new EventEmitter();

    presentLoader :EventEmitter<boolean> = new EventEmitter();
    showNotification:EventEmitter<boolean> = new EventEmitter();

    celebration:EventEmitter<boolean> = new EventEmitter();
    signUp: EventEmitter<boolean> = new EventEmitter();
    getQues: EventEmitter<boolean> = new EventEmitter();

    showVideo: EventEmitter<boolean> = new EventEmitter();
    showCatalog: EventEmitter<boolean> = new EventEmitter();
    navigaeParticularProduct: EventEmitter<boolean> = new EventEmitter();
    warrantyInformation
    checkWinnerStatus : EventEmitter<boolean> = new EventEmitter();
    correctProduct : EventEmitter<boolean> = new EventEmitter();

    private previousUrl: string;
    private currentUrl: string;

    isPopup: boolean = false;
  constructor(
    private http: HttpClient,
    private appSettings: MainAppSetting,
    private router: Router
  ) { 
    this.cartitem=[];
    this.cartdata=0;

    this.currentUrl = this.router.url;
        this.previousUrl = null;

        this.router.events
                    .pipe(filter((event: any) => event instanceof RoutesRecognized), pairwise())
                    .subscribe((events: RoutesRecognized[]) => {
                        this.previousUrl = events[0].urlAfterRedirects;
                        this.currentUrl = events[1].urlAfterRedirects;
                    });
  }
  

  public getPreviousUrl() {
    return this.previousUrl;
}

  LoadPageOnrouteChange(){
    this.LoadPage.next(true)
  }


  

  LoadSurpriseModal(){
    this.LoadModal.next(true)
  }
  shareProduct(){
    this.share_product.next(true)
  }

  submitUpi(){
    this.submit_upi.next(true)

  }
  flipsurpriseModal(){
    this.flipsurprise_modal.next(true)
  }

  triggerLocation(){

    this.trigger_location.next(true)

  }

  popNotification(){

    this.showNotification.next(true)

  }

  showConfetti(){
    this.celebration.next(true)

  }

  toggleSignup(){
    this.signUp.next(true)

  }

  openLoader(){
    this.presentLoader.next(true)
  }

  openQuizModal(){
    this.getQues.next(true)
  }


  openVideoModal(){
    // this.getQues.next(true)
    this.showVideo.next(true)
  }

  showCatalogpage(){
    this.showCatalog.next(true)
    
  }
  openParticularProduct(){
    this.navigaeParticularProduct.next(true)
  }
  winnerStatus(){
    this.checkWinnerStatus.next(true);
  }

  loadAuthenticModal(){
this.correctProduct.next(true)
  }
}
