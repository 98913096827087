import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainAppSetting } from 'src/app/conatants/MainAppSetting';
import { Utils } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class NailaService {
  constructor(
    private http: HttpClient,
    private appSettings: MainAppSetting,
    public utils: Utils
  ) { }

  getEstimateById(id): Observable<any> {
    return this.http.get(`${this.appSettings.getApi()}/api/estimate/${id}?populate=statusChangedBy`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token')
        })
      });
  }
  updateEstimate(data): Observable<any> {
    return this.http.post(`${this.appSettings.getApi()}/api/estimate/${data._id}`, data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token')
        })
      });
  }


listBanners():Observable<any>{
  return this.http.get(`${this.appSettings.getApi()}/products/getRelatedProductD`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    //   Authorization: localStorage.getItem('token')
    credentials: 'include',

              
  }),
  withCredentials:true,
  })
}

  apartmentList() {
    return this.http.get(`${this.appSettings.getApi()}/api/v1/apartments/active`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      });
  }


  browseBycategory(){
    return this.http.get(`${this.appSettings.getApi()}/api/v1/categories/active`,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      //   Authorization: localStorage.getItem('token')
      })
    })
  }


  selectedCategory(data){
    return this.http.get(`${this.appSettings.getApi()}/api/v1/categories/${data}/services`,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      //   Authorization: localStorage.getItem('token')
      })
    })
  }





// 

listAllBookings(data){
  return this.http.get(`${this.appSettings.getApi()}/api/v1/bookings/users/${data}`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    //   Authorization: localStorage.getItem('token')
    })
  })
}



listAllTickets(data){
  return this.http.get(`${this.appSettings.getApi()}/api/v1/tickets/${data}`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    //   Authorization: localStorage.getItem('token')
    })
  })
}





listserviceByid(id){
  return this.http.get(`${this.appSettings.getApi()}/api/v1/services/${id}`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    // Authorization: localStorage.getItem('token')
    })
  })
}
applyCoupon(data){
  return this.http.get(`${this.appSettings.getApi()}/api/v1/bookings/coupons/${data}`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    // Authorization: localStorage.getItem('token')
    })
  })
}
getAvailbleSlots(data){
  return this.http.post(`${this.appSettings.getApi()}/api/v1/bookings/get_slots`, data,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: localStorage.getItem('token')
    })
  });
}

createBooking(data){
  return this.http.post(`${this.appSettings.getApi()}/api/v1/bookings`, data,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: localStorage.getItem('token')
    })
  });
}



createTicket(data){
  return this.http.post(`${this.appSettings.getApi()}/api/v1/tickets`, data,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: localStorage.getItem('token')
    })
  });
}


markAttendance(data){
  return this.http.post(`${this.appSettings.getApi()}/api/v1/attendances/punch_in`, data,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: localStorage.getItem('token')
    })
  });
}


updateAttendance(data,punchin_id){
  return this.http.put(`${this.appSettings.getApi()}/api/v1/attendances/ ${punchin_id}/punch_out`, data,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: localStorage.getItem('token')
    })
  });
}



getBookingForBeautician(data){
  return this.http.get(`${this.appSettings.getApi()}/api/v1/bookings/beauticians/${data}`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    // Authorization: localStorage.getItem('token')
    })
  })
}


updatepaymentStatus(paymentdata,data){
  return this.http.put(`${this.appSettings.getApi()}/api/v1/bookings/${data.id}`, paymentdata,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: localStorage.getItem('token')
    })
  });
}


//nowverifyit api


callGetTag(id){
  // nfc/get


  return this.http.get(`${this.appSettings.getApi()}/nfc/get/${id}/2`,{
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('token'),
    credentials: 'include',
    })
  })
}



callRecordScan(data){
  return this.http.get(`${this.appSettings.getApi()}/nfc/recordscan/${data.tagId}?location=${data.location}&lat=${data.lat}&long=${data.long}&pincode=${data.pincode}&city=${data.city}&state=${data.state}&country=${data.country}&source_token=${data.source_token}`,
  {
    headers: new HttpHeaders({
      
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      Authorization: localStorage.getItem('token'),
      credentials: 'include',

      
    }),
    withCredentials:true,

  }
  );
}


callPostBoughtIt(tagId): Observable<any> {


  return this.http.post(`${this.appSettings.getApi()}/nfc/bought`, tagId,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    })
  });

}


writeNFCQRcodedata(data):Observable<any>{

  return this.http.post(`${this.appSettings.getApi()}/nfc/post/${data.name}/${data.place}`,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    })
  });
}


genetateOTP(data,brandid):Observable<any>{

  return this.http.get(`${this.appSettings.getApi()}/Nfc/sendSMS/${data}/${brandid}`,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
      credentials: 'include',

              
    }),
    withCredentials:true,
  });
}

// https://develop.nowverifyit.com/Nfc/sendSMS/7405243168


submitOTP(data):Observable<any>{

  return this.http.get(`${this.appSettings.getApi()}/nfc/authPurchase/${data.tagId}/${data.otp}/${data.mobile}`,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
      credentials: 'include',

              
    }),
    withCredentials:true,
  });
}



listRelatedProducts(data){
  return this.http.get(`${this.appSettings.getApi()}/products/getRelatedProductDetails/${data}`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    credentials: 'include',

              
  }),
  withCredentials:true,
  })
}
listRelatedProductsfrombrand(data){
  return this.http.get(`${this.appSettings.getApi()}/products/getRelatedProductDetails/0/${data}`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    credentials: 'include',

              
  }),
  withCredentials:true,
  })
}

winAmount(){
  return this.http.get(`${this.appSettings.getApi()}/Nfc/getWinData `,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    credentials: 'include',

              
  }),
  withCredentials:true,
  })
}

getMyReferrer(){
  return this.http.get(`${this.appSettings.getApi()}/UserSetting/getMyReferrer `,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    credentials: 'include',

              
  }),
  withCredentials:true,
  })
}

getRewardsData(){
  return this.http.get(`${this.appSettings.getApi()}/Nfc/getrewardData `,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    credentials: 'include',

              
  }),
  withCredentials:true,
  })
}

genToken(data,params){
  return this.http.post(`${this.appSettings.getApi()}/login/gentoken/${params}`, data,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: localStorage.getItem('token'),
    credentials: 'include',

              
  }),
  withCredentials:true,
  })
}

reviewTracking(data){
  // return this.http.post(`${this.appSettings.getApi()}/tracking/review_tracking`, data,
  data.tag_id=window.localStorage.getItem('tagId')
  return this.http.post(`${this.appSettings.getApi()}/tracking/tracking`, data,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token')
    })
  });
}



getHtml(data){
  // return this.http.post(`${this.appSettings.getApi()}/tracking/review_tracking`, data,
  return this.http.get(data,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token')
    })
  });
}


getLoyaltyPointByuser(data){
 
  return this.http.get(`${this.appSettings.getApi()}/loyaltypoints/getloyaltyofuser`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    credentials: 'include',

              
  }),
  withCredentials:true,
  })
}
//Charu Start
get_reviews(data){
      return this.http.post(`${this.appSettings.getApi()}/Reviews/get_reviews`,data,{
       headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'), 
             
  }),
  withCredentials:true,
  })
}
save_reviews(data){
  return this.http.post(`${this.appSettings.getApi()}/Reviews/save_review`,data,{
   headers: new HttpHeaders({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('token'), 
         
}),
withCredentials:true,
})
}




//**Charu End */





trackingApi(data): Observable<any> {
  return this.http.post(`${this.appSettings.getApi()}/tracking/tracking`, data,
    {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token')
      })
    });
}

getQuestion(data){
  if(this.utils.source_token) {
    data.source=this.utils.source_token;
  }
  return this.http.post(`${this.appSettings.getApi()}/PopupQuestions/getQuestion`,data,{
   headers: new HttpHeaders({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('token'), 
         
}),
withCredentials:true,
})
}
saveAnswers(data){
 
return this.http.post(`${this.appSettings.getApi()}/PopupQuestions/saveAnswers`,data,{
 headers: new HttpHeaders({
'Content-Type': 'application/json',
Authorization: localStorage.getItem('token'), 
       
}),
withCredentials:true,
})
}


checkWinStatus(data){
  data.tag_id=window.localStorage.getItem('tagId');
  data.mobile=0;
  if(this.utils.source_token) {
    data.source=this.utils.source_token;
  }
  
  let mobile = Number(window.localStorage.getItem('mobile'));
  if(mobile!=null){
    data.mobile=Number(window.localStorage.getItem('mobile'));
  }else if(mobile==null){
    data.mobile=0
  }else{
    data.mobile=window.localStorage.getItem('paytmnumber');
  }
  return this.http.post(`${this.appSettings.getApi()}/UserSetting/check_winner`,data,{
    headers: new HttpHeaders({
   'Content-Type': 'application/json',
   Authorization: localStorage.getItem('token'), 
          
   }),
   withCredentials:true,
   })
}


submitBankDetail(data): Observable<any> {


  return this.http.post(`${this.appSettings.getApi()}/UserSetting/save_user_bank_detail`, data,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    })
  });

}


getBrandDetail(data){
  return this.http.post(`${this.appSettings.getApi()}/nfc/get_brand_datail/${data}`, data,
  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    })
  });
}


authenticateProduct(data){
  return this.http.get(`${this.appSettings.getApi()}/register/checkauthentication/${data}`,{
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    credentials: 'include',

              
  }),
  withCredentials:true,
  })
}

}
