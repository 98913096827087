// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyDHsflURkffjc7-EoZdkvrvkackVD-JJEQ",
//     authDomain: "nowverifycap.firebaseapp.com",
//     projectId: "nowverifycap",
//     storageBucket: "nowverifycap.appspot.com",
//     messagingSenderId: "78693282361",
//     appId: "1:78693282361:web:c03e0bfb6f2294c4fbd3e8",
//     measurementId: "G-Z3TV886JGJ"
//   }
// };
export const environment = {
  production: false,
  firebase: {
    //********** For Prod deployment **********
    // apiKey: "AIzaSyBWwl-fvDoiwidxf0hbXUlf6CpfP7tSap0",
    // authDomain: "nowverifyit-f7758.firebaseapp.com",
    // projectId: "nowverifyit-f7758",
    // storageBucket: "nowverifyit-f7758.appspot.com",
    // messagingSenderId: "182516696535",
    // appId: "1:182516696535:web:ad6e088a36b54a9e387792",
    // measurementId: "G-V4Y8C7JGRH"

    //********** For Dev deployment **********
    apiKey: "AIzaSyD1KCYrMGffKaXXGxft7GtZ_waMfqpazYo",
    authDomain: "dev-nowverifyit.firebaseapp.com",
    projectId: "dev-nowverifyit",
    storageBucket: "dev-nowverifyit.appspot.com",
    messagingSenderId: "38433837703",
    appId: "1:38433837703:web:f30e5b82a4223e7a8a9e22",
    measurementId: "G-LWQ0F603PM",

    // apiKey: "AIzaSyDHsflURkffjc7-EoZdkvrvkackVD-JJEQ",
    // authDomain: "nowverifycap.firebaseapp.com",
    // projectId: "nowverifycap",
    // storageBucket: "nowverifycap.appspot.com",
    // messagingSenderId: "78693282361",
    // appId: "1:78693282361:web:c03e0bfb6f2294c4fbd3e8",
    // measurementId: "G-Z3TV886JGJ"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
