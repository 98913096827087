import {
  Component,
  OnInit,
  NgZone,
  AfterViewInit,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { Device } from "@ionic-native/device/ngx";
import { Subscription } from "rxjs";

export let browserRefresh = false;

import {
  Platform,
  NavController,
  LoadingController,
  AlertController,
  ToastController,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "./common-services/storage-service.service";
import { Storage } from "@ionic/storage";
import { RentalsUserService } from "./Rentals Management/services/rentals-user.service";
import { AlertServiceService } from "./common-services/alert-service.service";
import { BuildingUserService } from "./Building-Management/services/building-user.service";
import { Utils } from "./Rentals Management/services/utils.service";
import { NFC, Ndef } from "@ionic-native/nfc/ngx";
import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { Console } from "@angular/core/src/console";
import { VerifyitAccountsPage } from "./Rentals Management/pages/verifyitaccountspage/verifyitaccountspage";
import { NailaService } from "./Rentals Management/services/naila.service";
import { SettingsService } from "./settings.service";
import { Plugins } from "@capacitor/core";
// import { MessagingService } from '../services/messaging.service';

declare var wkWebView: any;

// import {
//   ActionPerformed,
//   PushNotificationSchema,
//   PushNotifications,
//   Token,
// } from '@capacitor/push-notifications';
import { AutocloseOverlaysService } from "./Rentals Management/services/autoclose.service";
import { MessagingService } from "./services/messaging.service";
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { AdMob } from "@capacitor-community/admob";
// const { PushNotifications } = Plugins;

interface DeeplinkMatch {
  $myparam: string;
}
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {
  userrole;
  subscription: Subscription;

  firstname;
  selectedTheme: String = "red-theme";

  public appPages = {
    name: "",

    phoneNumber: localStorage.getItem("phoneNumber"),
    pages: [
      //     {
      //   title: "Home",
      //   url: `verifyit-dashboard`,
      //   src: "/assets/imgs/home.svg",
      //   userrole: 'default'
      // },
      {
        title: "Read NFC/QR",
        url: `verifyit-dashboard`,
        src: "/assets/imgs/whitenfc.png",
        userrole: "default",
      },
      {
        title: "Write NFC/QR",
        url: `verifyit-dashboard`,
        src: "/assets/imgs/whitenfc.png",
        userrole: "2",
      },
      // {
      //   title: "Read QR",
      //   url: `verifyit-dashboard`,
      //   src: "/assets/imgs/whiteqrcode.jpg",
      //   userrole: window.localStorage.getItem("userType")
      // },
      // {
      //   title: "Write QR",
      //   url: `verifyit-dashboard`,
      //   src: "/assets/imgs/whiteqrcode.jpg",
      //   userrole: window.localStorage.getItem("userType")
      // },
      {
        title: "Account",
        url: `verifyit-account`,
        src: "assets/imgs/profile1.svg",
        userrole: "default",
      },
      {
        title: "Referee Details",
        url: `referee-details`,
        src: "assets/imgs/profile1.svg",
        userrole: "default",
      },
      // {
      //   title: 'Tickets',
      // url: `rentals-naila-ticket-page`,
      //   src: '/assets/imgs/business.svg'
      // },
      // {
      //   title: 'app-component.contact-us',
      // url: `-contact-us`,
      //   src: '/assets/icon/phone.png'
      // },
      // {
      //   title: 'Project ',
      // url: `-my-data-project`,
      //   src: '/assets/icon/phone.png'
      // },
      // {
      //   title: 'Bookings',
      //   url: `rentals-naila-beaut-booking-page`,
      //   src: '/assets/imgs/bookings.svg',
      //   userrole:'Beautician'
      // },
      // {
      //   title: 'Attendance',
      //   url: `rentals-naila-beaut-attendance-page`,
      //   src: '/assets/imgs/business.svg',
      //   userrole:'Beautician'

      {
        title: "Rewards",
        url: `verifyit-rewards`,
        src: "/assets/imgs/offers.svg",
        userrole: "default",
      },
      {
        title: "Offers",
        url: `verifyit-offer`,
        src: "/assets/imgs/commerce-and-shopping.svg",
        userrole: "default",
      },
      // {
      //   title: 'Log Out',
      //   // url: `rentals-naila-cart-page`,
      //   src: '/assets/imgs/logoutsearch.svg'
      // }
    ],
  };

  public appPages2 = {
    name: "",

    phoneNumber: localStorage.getItem("phoneNumber"),
    pages: [
      //     {
      //   title: "Home",
      //   url: `verifyit-dashboard`,
      //   src: "/assets/imgs/home.svg",
      //   userrole: 'default'
      // },
      // {
      //   title: "Read QR",
      //   url: `verifyit-dashboard`,
      //   src: "/assets/imgs/whiteqrcode.png",
      //   userrole: "default",
      // },
      {
        title: "Write QR",
        url: `verifyit-dashboard`,
        src: "/assets/imgs/whiteqrcode.png",
        userrole: "2",
      },
      // {
      //   title: "Read QR",
      //   url: `verifyit-dashboard`,
      //   src: "/assets/imgs/whiteqrcode.jpg",
      //   userrole: window.localStorage.getItem("userType")
      // },
      // {
      //   title: "Write QR",
      //   url: `verifyit-dashboard`,
      //   src: "/assets/imgs/whiteqrcode.jpg",
      //   userrole: window.localStorage.getItem("userType")
      // },
      {
        title: "Account",
        url: `verifyit-account`,
        src: "assets/imgs/profile1.svg",
        userrole: "default",
      },
      {
        title: "Referee Details",
        url: `referee-details`,
        src: "assets/imgs/profile1.svg",
        userrole: "default",
      },
      {
        title: "Rewards",
        url: `verifyit-rewards`,
        src: "/assets/imgs/dollar.svg",
        userrole: "default",
      },

      {
        title: "Offers",
        url: `verifyit-offer`,
        src: "/assets/imgs/commerce-and-shopping.svg",
        userrole: "default",
      },
      // {
      //   title: 'Tickets',
      // url: `rentals-naila-ticket-page`,
      //   src: '/assets/imgs/business.svg'
      // },
      // {
      //   title: 'app-component.contact-us',
      // url: `-contact-us`,
      //   src: '/assets/icon/phone.png'
      // },
      // {
      //   title: 'Project ',
      // url: `-my-data-project`,
      //   src: '/assets/icon/phone.png'
      // },
      // {
      //   title: 'Bookings',
      //   url: `rentals-naila-beaut-booking-page`,
      //   src: '/assets/imgs/bookings.svg',
      //   userrole:'Beautician'
      // },
      // {
      //   title: 'Attendance',
      //   url: `rentals-naila-beaut-attendance-page`,
      //   src: '/assets/imgs/business.svg',
      //   userrole:'Beautician'

      // },
      // {
      //   title: 'Log Out',
      //   // url: `rentals-naila-cart-page`,
      //   src: '/assets/imgs/logoutsearch.svg'
      // }
    ],
  };

  fcmData = {
    android_fcm: "",
    ios_fcm: "",
    js_fcm: "",
  };
  username = "";
  showMenulist;

  deferredPrompt: any;
  showButton = false;
  oType: any;
  @HostListener("window:beforeinstallprompt", ["$event"])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }

  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
      } else {
      }
      this.deferredPrompt = null;
    });
  }
  ngOnInit() {
    this.utils.LoadPage.subscribe((data) => {
      if (window.localStorage.getItem("userType")) {
        this.userrole = window.localStorage.getItem("userType");
        this.username = window.localStorage.getItem("name");
        this.showMenulist = true;
        this.ionViewDidLoad();
      } else {
        this.showMenulist = false;
        this.ionViewDidLoad();
      }
    });

    if (!window.localStorage.getItem("userType")) {
      this.userrole = window.localStorage.setItem("userType", "1");
    }
    this.username = window.localStorage.getItem("name");
    this.userrole = window.localStorage.getItem("userType");
    this.initializeApp();
    this.getSimReceiver();
  }

  public appSrc;
  // options: PushOptions = {
  //   android: {},
  //   ios: {
  //   },
  // }
  // pushObject: PushObject = this.push.init(this.options);

  constructor(
    private zone: NgZone,
    private messagingService: MessagingService,
    protected deeplinks: Deeplinks,
    private nfc: NFC,
    private ndef: Ndef,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private device: Device,
    private toastCtrl: ToastController,
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    public translate: TranslateService,
    private storageService: StorageService,
    private storage: Storage,
    private loadingCtrl: LoadingController,
    private rentalsUserService: RentalsUserService,
    private alertService: AlertServiceService,
    private buildingUserService: BuildingUserService,
    private autocloseOverlaysService: AutocloseOverlaysService,
    private utils: Utils,
    private verifyitservice: NailaService,
    private settings: SettingsService,
    private smsRetriever: SmsRetriever,
    private alertCtrl: AlertController // private push: Push
  ) {
    this.listenForMessages();

    this.settings
      .getActiveTheme()
      .subscribe((val) => (this.selectedTheme = val));

    this.router.events.subscribe((event: any): void => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === "popstate") {
          this.autocloseOverlaysService.trigger();
        }
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params.server) {
        if (window.localStorage.getItem("server") != params.server) {
          window.localStorage.clear();
        }

        window.localStorage.setItem("server", params.server);
      }

      // if (params) {
      //   let queryParams = JSON.parse(params);
      // }
    });
  }

  populatemenu = true;
  p = {
    userrole: "",
  };
  toggleRole(role, title) {
    // this.populatemenu=!this.populatemenu

    if (role == "2") {
      this.p.userrole = "1";
    } else if (role == "1") {
      this.p.userrole = "2";
    }
    // if (title != "Account") {

    //   this.utils.LoadPageOnrouteChange();
    // }else
    if (title == "Read NFC/QR") {
      this.utils.menuTitle = "Read NFC/QR";
      this.utils.LoadPageOnrouteChange();
    } else if (title == "Write NFC/QR") {
      this.utils.menuTitle = "Write NFC/QR";
      this.utils.LoadPageOnrouteChange();
    } else if (title == "Write QR") {
      this.utils.menuTitle = "Write NFC/QR";
      this.utils.LoadPageOnrouteChange();
    } else if (title == "Read QR") {
      this.utils.menuTitle = "Read NFC/QR";
      this.utils.LoadPageOnrouteChange();
    }

    if (title == "Read QR") {
      this.oType = "MENU_READQR_CLICK";
    } else if (title == "Account") {
      this.oType = "MENU_ACCOUNT_CLICK";
    } else if (title == "Rewards") {
      this.oType = "MENU_REWARDS_CLICK";
    } else if (title == "Offers") {
      this.oType = "MENU_OFFERS_CLICK";
    }
    this.onClickMenu(this.oType);
  }

  async ionViewDidLoad() {
    this.platform.ready().then(() => {
      this.nfc
        .enabled()
        .then((resolve) => {
          this.canNFC = true;
        })
        .catch((reject) => {
          this.canNFC = false;
        });
    });
  }

  async presentLoading() {
    await this.loadingCtrl
      .create({
        spinner: "lines",
      })
      .then((loading) => {
        loading.present();
      });
  }

  async routeForword(url) {
    await this.storageService.getDatafromIonicStorage("appSrc").then((val) => {
      this.appSrc = val;
      this.router.navigateByUrl(`${url}`);
      // this.router.navigateByUrl(`${this.appSrc}${url}`)
    });
  }
  canNFC = false;
  async initializeApp() {
    let isLoggedIn: string;
    // const device = await this.device.getInfo();
    window.localStorage.setItem("device_id", this.device.uuid);
    // alert(device.uuid)
    if (!window.localStorage.getItem("token")) {
      window.localStorage.setItem("token", "");
      this.storageService.storeDataToIonicStorage("token", "");
    }

    //  this.requestPermission()
    this.platform.ready().then(() => {
      /**
       * initialize() require after platform.ready();
       */
      AdMob.initialize({
        requestTrackingAuthorization: true,
        testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
        initializeForTesting: true,
      });
    });
  }
  redirectToHomeOrLogin(isLoggedIn) {
    window.localStorage.getItem("uid");
    const registereduser = window.localStorage.getItem("registereduser");
    if (window.localStorage.getItem("user_type") == "Beautician") {
      registereduser == "true"
        ? this.navCtrl.navigateRoot("/rentals-naila-beaut-booking-page")
        : this.navCtrl.navigateRoot("/login");
    } else if (window.localStorage.getItem("user_type") == "Customer") {
      registereduser == "true"
        ? this.navCtrl.navigateRoot("/rentals-naila-search-page")
        : this.navCtrl.navigateRoot("/login");
    }

    if (
      window.localStorage.getItem("cartitem") &&
      window.localStorage.getItem("cartitemcount")
    ) {
      this.utils.cartitem = JSON.parse(window.localStorage.getItem("cartitem"));
      this.utils.cartdata = window.localStorage.getItem("cartitemcount");
    }
  }
  // logout() {
  //   window.localStorage.clear()
  //   this.storage.clear()
  //   this.router.navigateByUrl('/login')
  // }

  async logOut() {
    await this.presentLoading();

    window.localStorage.clear();
    this.router.navigateByUrl("/login");
    await this.loadingCtrl.dismiss();

    //   this.storage.clear()
    //   this.router.navigateByUrl('/login')
    // let userId;
    // await this.storageService.getDatafromIonicStorage('user_id').then(val => {
    //   userId = val;
    // })
    // this.storageService.getDatafromIonicStorage('appSrc').then(val => {
    //   if (val == 'rentals') {
    //     this.rentalsUserService.getUserById(userId).subscribe(async data => {
    //       if (data.businessAppDevice.pushToken) {
    //         delete data.businessAppDevice
    //         this.updateUser(val, data)
    //       } else {
    //         await this.loadingCtrl.dismiss()
    //         window.localStorage.clear();
    //         await this.storageService.emptyStorage()
    //         this.navCtrl.navigateRoot('/login');
    //       }

    //     })
    //   } else if (val == 'building-management') {
    //     this.buildingUserService.getUserById(userId).subscribe(async data => {
    //       if (data.businessAppDevice.pushToken) {
    //         delete data.businessAppDevice
    //         this.updateUser(val, data)
    //       } else {
    //         await this.loadingCtrl.dismiss()
    //         window.localStorage.clear();
    //         await this.storageService.emptyStorage()
    //         this.navCtrl.navigateRoot('/login');
    //       }
    //     })
    //   }

    // })
    // window.localStorage.clear();
    // await this.storage.clear()
    // this.navCtrl.navigateRoot('/login');
  }

  async updateUser(val, data) {
    if (val == "rentals") {
      this.rentalsUserService.updateUser(data).subscribe(
        async (data: any) => {
          await this.loadingCtrl.dismiss();
          window.localStorage.clear();
          await this.storage.clear();
          this.navCtrl.navigateRoot("/login");
        },
        async (err) => {
          await this.loadingCtrl.dismiss();
          this.alertService.presentAlert("", "Error while logging out");
        }
      );
    } else if (val == "building-management") {
      this.buildingUserService.updateUser(data).subscribe(
        async (data: any) => {
          await this.loadingCtrl.dismiss();
          window.localStorage.clear();
          await this.storage.clear();
          this.navCtrl.navigateRoot("/login");
        },
        async (err) => {
          await this.loadingCtrl.dismiss();
          this.alertService.presentAlert("", "Error while logging out");
        }
      );
    }
  }

  private _initTranslate() {
    this.translate.setDefaultLang("en");
    this.translate.use("en"); // Set your language here
  }

  navigatetologinpage() {
    this.router.navigateByUrl("/login");
  }

  async setupDeeplinks() {
    this.deeplinks.route("/").subscribe(
      (match) => {
        // Create our internal Router path by hand
        const internalPath = `/${match.$route}/${match.$args["slug"]}`;

        // Run the navigation in the Angular zone
        this.zone.run(() => {
          this.router.navigateByUrl(internalPath);
        });
      },
      (nomatch) => {
        // nomatch.$link - the full link data
        console.error(
          "Got a deeplink that didn't match",
          JSON.stringify(nomatch)
        );
      }
    );
  }

  ngAfterViewInit() {
    this.platform.ready().then(async () => {
      // await this.initDeepLinking();
      // await this.setupDeeplinks()
    });
  }

  // private async initDeepLinking(): Promise<void> {
  //   if (this.platform.is('cordova')) {
  //     await this.initDeepLinkingBranchio();
  //   } else {
  //     await this.initDeepLinkingWeb();
  //   }
  // }

  // private async initDeepLinkingWeb(): Promise<void> {
  //   const myparam: string =
  //     this.platform.getQueryParam('$myparam') ||
  //     this.platform.getQueryParam('myparam') ||
  //     this.platform.getQueryParam('%24myparam');
  // }

  // private async initDeepLinkingBranchio(): Promise<void> {
  //   try {
  //     const branchIo = window['Branch'];

  //     if (branchIo) {
  //       const data: DeeplinkMatch =
  //         await branchIo.initSession();

  //       if (data.$myparam !== undefined) {
  //         this.router.navigateByUrl('/verifyit-account')
  //       }
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  async generateToken(fcmData) {
    let token = window.localStorage.getItem("token");
    if (!token.length) {
      await this.verifyitservice.genToken(fcmData, "").subscribe(
        (data: any) => {
          window.localStorage.setItem("token", data.data.token);
        },
        async (err) => {
          await this.loadingCtrl.dismiss();
          this.alertService.presentAlert("", "Error while logging out");
        }
      );
    }
  }

  // pushNotificationInit() {
  //   PushNotifications.requestPermission().then((result) => {
  //     if (result.granted) {
  //       // Register with Apple / Google to receive push via APNS/FCM
  //       PushNotifications.register();
  //     } else {
  //       this.alertService.presentAlert(
  //         "",
  //         "Something went wrong in push notification registration"
  //       );
  //     }
  //   });

  //   PushNotifications.addListener(
  //     "registration",
  //     (token: PushNotificationToken) => {
  //       // alert('Push registration success, token: ' + token.value);
  //     }
  //   );

  //   PushNotifications.addListener("registrationError", (error: any) => {
  //     // alert('Error on registration: ' + JSON.stringify(error));
  //   });

  //   PushNotifications.addListener(
  //     "pushNotificationReceived",
  //     (notification: PushNotification) => {
  //       // this.router.navigateByUrl('/verifyit-product-info' )
  //       this.router.navigate(["/verifyit-product-info"], {
  //         queryParams: { brand: "openquiz" },
  //       });
  //       // alert('Push received: ' + JSON.stringify(notification));
  //     }
  //   );

  //   PushNotifications.addListener(
  //     "pushNotificationActionPerformed",
  //     (notification: PushNotificationActionPerformed) => {
  //       // this.router.navigateByUrl('/verifyit-rewards')
  //       // alert('Push action performed: ' + JSON.stringify(notification));
  //     }
  //   );
  // }

  // pwa push notification

  listenForMessages() {
    this.messagingService.getMessages().subscribe(
      async (msg: any) => {
        // const alert = await this.alertCtrl.create({
        //   header: '',
        //   subHeader: '',
        //   message: '',
        //   buttons: ["OK"],
        // });
        // await alert.present();
      },
      (err) => {
        // alert('hi')
      }
    );
  }

  // requestPermission() {
  //   this.messagingService.requestPermission().subscribe(
  //     async (token) => {
  //       this.fcmData.js_fcm = token;
  //       this.generateToken(this.fcmData);
  //       // const toast = await this.toastCtrl.create({
  //       //   message: "Got your token",
  //       //   duration: 2000,
  //       // });
  //       // toast.present();
  //     },
  //     async (err) => {
  //       this.generateToken(this.fcmData);
  //       // const alert = await this.alertCtrl.create({
  //       //   header: "Error",
  //       //   message: err,
  //       //   buttons: ["OK"],
  //       // });

  //       // await alert.present();
  //     }
  //   );
  // }

  async deleteToken() {
    this.messagingService.deleteToken();
    const toast = await this.toastCtrl.create({
      message: "Token removed",
      duration: 2000,
    });
    toast.present();
  }

  subscription3;
  ngOnDestroy() {
    // subscription: Subscription;

    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !this.router.navigated;
      }
    });
  }

  onClickMenu(otype) {
    let shareData = {
      user_id: localStorage.getItem("userid"),
      tag_id: localStorage.getItem("tagId"),
      product_id: localStorage.getItem("productId"),
      device_id: localStorage.getItem("device_id"),
      otype: otype,
    };
    this.verifyitservice.reviewTracking(shareData).subscribe(
      (res: any) => {},
      (err) => {
        console.error(JSON.stringify(err));
      }
    );
  }

  getSimReceiver() {
    this.smsRetriever
      .getAppHash()
      .then((res: any) => {
        console.log("getAppHash res", res);
      })
      .catch((error: any) => {
        console.log("getAppHash error", error);
        console.error(error);
      });
    this.smsRetriever
      .startWatching()
      .then((res: any) => {
        console.log("startWatching res", res);
      })
      .catch((error: any) => {
        console.log("startWatching error", error);
        console.error(error);
      });
  }
}
